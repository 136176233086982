'use client'

import {ContentLayout} from "@/components/molecules/layouts";
import {NeutralMessage, Title} from "@/components/atoms/typographies";
import {SCenterLinkButton} from "@/components/atoms/button";

export default function Error({
                                error,
                                reset,
                              }: {
  error: Error & { digest?: string }
  reset: () => void
}) {

  return (
    <ContentLayout>
      <Title>Ooops!</Title>
      <NeutralMessage>Une erreur s'est produite. Pas de panique, l'erreur a été reportée.</NeutralMessage>
      <SCenterLinkButton href="/">Retourner à l'accueil</SCenterLinkButton>
    </ContentLayout>
  )
}